import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  debounce,
  Tooltip,
  // Paper,
  // Divider,
  // Checkbox,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import MDTypography from "components/MDTypography";
import { createGame, getGameConfig, getCryptos } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";
import FreeGameForm from "./FreeGameForm";
import MultiSelect from "./MultiSelect";
import { getBlockChianConfig } from "services/api/gameAPI";
import { calCustomUser } from "services/api/gameAPI";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";

const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 700, lg: 800 },
  height: { xs: 300, sm: 350, md: 450, lg: 550 },
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
};

function GameCreate() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  // const [counter, setCounter] = useState(1);
  const [gameConfig, setGameConfig] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [startTimeError, setStartTimeError] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [gamedata, setgamedata] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [error, setError] = useState({ cryptos: "", positions: "" });
  // const [gameCategory, setGameCategory] = useState(false);

  const [selectedOption, setSelectedOption] = useState("paid");

  //
  const [minPlayer, setMinPlayer] = useState(0);
  const [maxPlayer, setMaxPlayer] = useState(0);
  const [prizeMoney, setPrizeMoney] = useState(0);
  const [entryFees, setEntryFees] = useState();
  const [userLimit, setUserLimit] = useState(0);

  const [profitPercentage, setProfitPercentage] = useState(0);

  const [fupApiError, setFupApiError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        try {
          const cryptosData = await getCryptos();
          const gameConfigData = await getGameConfig();
          const config = await getBlockChianConfig({});
          // console.log(config?.data?.adminProfitPercentage);
          setProfitPercentage(config?.data?.adminProfitPercentage);
          setCryptos(cryptosData.data.data);
          setGameConfig(gameConfigData.data.data);
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [open]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { handleSubmit, control } = useForm({
    // resolver: yupResolver(ProductSchema),
  });

  const CreateGame = (gameData) => {
    const isAnyPrizeNull = data.some((item) => item.prize === undefined || item.prize === "");

    if (gameData.cryptos.length < gameData.gameType) {
      setError({
        ...error,
        cryptos: `Please select atleast ${gameData.gameType} cryptos to continue `,
      });
      return;
    }

    if (isAnyPrizeNull) {
      setError({ ...error, positions: "Please fill all the prize values" });
      console.log("One or more prize values are null or empty. Cannot create game.");
      return; // Do not proceed further
    }

    setgamedata(gameData);
    setAlertOpen(true);
  };

  useEffect(() => {
    const handleCalculatePrizeMoney = async () => {
      // const totalAmount = entryFees * minPlayer;
      // const totalprize = entryFees * minPlayer * 0.2;
      // setPrizeMoney(totalAmount - totalprize);

      const totalAmount = entryFees * minPlayer;
      const totalprize = entryFees * minPlayer * (parseInt(profitPercentage ?? 0) / 100);
      setPrizeMoney(totalAmount - totalprize);
      // }
    };
    handleCalculatePrizeMoney();
  }, [entryFees, minPlayer]);

  // console.log(cryptos, "cryptos");

  // Debounce the API call to avoid making API calls on every keystroke
  const debouncedCheckUserAvailability = useCallback(
    debounce((per, minPlayer) => {
      handleUserAvailability(per, minPlayer);
    }, 300),
    [] // The empty array ensures the debounce function is created only once
  );

  const handleUserAvailability = async (per, minPlayer) => {
    try {
      setFupApiError(null);

      if (Number(per) <= 0) return;
      if (!per) return false;

      const response = await calCustomUser({
        enteredPercentage: parseInt(per),
        minPlayer: minPlayer,
      });
      const data = response.data;
      console.log(data);
      if (data?.data?.available == false) {
        setFupApiError("Not have enough users to add");
        return false;
      } else {
        setFupApiError(null);
        return true;
      }
    } catch (error) {
      console.error("API call failed:", error);
      setFupApiError("Failed to check user availability");
      return false;
    }
  };

  return (
    <>
      <div>
        <Button onClick={handleOpen} variant="contained">
          Create Game
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                    padding: 3,
                  }}
                >
                  <MDTypography
                    variant="h5"
                    color="white"
                    p={1.5}
                    sx={{ textAlign: "center", marginTop: "-21px" }}
                  >
                    Create Game
                  </MDTypography>
                  <Box display={"flex"} alignItems={"center"} gap={4} marginBottom={2}>
                    <Typography variant="h6" gutterBottom>
                      Payment Type :
                    </Typography>

                    <RadioGroup
                      row
                      aria-label="form-type"
                      name="form-type"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <FormControlLabel value="paid" control={<Radio />} label="Paid" />
                      <FormControlLabel value="free" control={<Radio />} label="Free" />
                    </RadioGroup>
                  </Box>

                  {selectedOption === "free" && (
                    <>
                      <FreeGameForm gameConfig={gameConfig} cryptos={cryptos} setOpen={setOpen} />
                    </>
                  )}

                  {selectedOption === "paid" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        padding: 3,
                        flexWrap: "wrap",
                        flexDirection: "column",
                        mt: -5,
                      }}
                      component="form"
                      onSubmit={handleSubmit(CreateGame)}
                    >
                      {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <Controller
                          name="gameName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Game name is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-basic"
                              label="Game Name"
                              value={value}
                              onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              helperText={error ? error.message : null}
                              autoComplete="off"
                            />
                          )}
                        />
                        {/* 
                          <Controller
                            name="gameCategory"
                            control={control}
                            defaultValue={undefined}
                            rules={{ required: "Game category is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ width: 180 }}
                                // getOptionLabel={(option: any) => option.product_name}
                                options={
                                  gameConfig?.gameCategory?.map((category) => ({
                                    label: category,
                                  })) || []
                                }
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                  onChange(newValue?.label || ""); // Use newValue instead of value
                                  //frequent and one time game mode applicable only for multiplayer games
                                  if (newValue?.label === "Multiplayer") {
                                    setGameCategory(true);
                                  } else {
                                    setGameCategory(false);
                                    setMinPlayer(2);
                                    setMaxPlayer(2);
                                  }
                                }}
                                value={
                                  value
                                    ? gameConfig?.gameCategory?.find(
                                        (option) => option.label === value
                                      )
                                    : undefined
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="outlined-basic"
                                    label="Game Category"
                                    variant="outlined"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: 45,
                                      },
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                              />
                            )}
                          /> */}

                        <Controller
                          name="gameCategory"
                          control={control}
                          defaultValue={"Multiplayer"}
                          rules={{ required: "Game category is required" }}
                          render={({ field: { value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-basic"
                              label="Game category"
                              value={"Multiplayer"}
                              // onChange={onChange}
                              variant="outlined"
                              error={!!error}
                              // disabled
                              helperText={error ? error.message : null}
                              autoComplete="off"
                            />
                          )}
                        />
                        {/* {gameCategory ? ( */}
                        <Controller
                          name="payoutStructure"
                          control={control}
                          defaultValue={""}
                          rules={{ required: "Payout Structure is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180 }}
                              options={gameConfig?.payout ?? []}
                              getOptionLabel={(option) =>
                                (option?.name ? `${option?.name ?? ""} - ` : "") +
                                " Top " +
                                option?.minPlayer
                              }
                              onChange={(event, newValue) => {
                                onChange(newValue?.id || "");
                                // Use newValue instead of value
                                setMinPlayer(parseInt(newValue?.minPlayer ?? 0));
                                setMaxPlayer((newValue?.minPlayer ?? 0) * 2);
                                setFupApiError(null);
                                debouncedCheckUserAvailability(
                                  userLimit,
                                  parseInt(newValue?.minPlayer ?? 0)
                                );
                              }}
                              value={
                                value
                                  ? gameConfig?.payout?.find((option) => value === option?.id)
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Payout Structure"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error?.message : null}
                                />
                              )}
                            />
                          )}
                        />
                        {/* ) : (
                            ""
                          )} */}

                        {/* {gameCategory ? (
                          <Controller
                            name="payoutStructure"
                            control={control}
                            defaultValue={undefined}
                            rules={{ required: "payoutStructure is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ width: 180 }}
                                options={
                                  gameConfig?.payout?.map((payout) => ({
                                    label: payout.minPlayer,
                                  })) || []
                                }
                                getOptionLabel={(option) => option?.label}
                                onChange={(event, newValue) => {
                                  onChange(newValue?.label.toString() || "");
                                  // Use newValue instead of value
                                  setMinPlayer(parseInt(newValue?.label));
                                  setMaxPlayer(newValue?.label * 2);
                                }}
                                value={
                                  value
                                    ? gameConfig?.payout?.find((option) => option?.label === value)
                                    : undefined
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="outlined-basic"
                                    label="Payout Structure"
                                    variant="outlined"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: 45,
                                      },
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />
                                )}
                              />
                            )}
                          />
                        ) : (
                          ""
                        )} */}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Controller
                          name="maxPlayer"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Maximum players is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => ( */}
                        <TextField
                          id="outlined-basic"
                          label="Maximum Players"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={maxPlayer ?? 0}
                          // onChange={(e) => onChange(Number(e.target.value) || "")}
                          // error={!!error}
                          // helperText={error ? error.message : null}
                          // autoComplete="off"
                        />
                        {/* )}
                        /> */}
                        {/* <Controller
                          name="minPlayer"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Minimum players is required" }}
                          render={({ field: { onChange }, fieldState: { error } }) => ( */}
                        <TextField
                          id="outlined-basic"
                          name="minPlayer"
                          label="Minimum Players"
                          type="number"
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={minPlayer}
                          // onChange={(e) => onChange(e.target.value)}
                          // error={!!error}
                          // helperText={error ? error.message : null}
                          // autoComplete="off"
                        />
                        {/* )}
                        /> */}
                        <Controller
                          name="entryFees"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Entry fees is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-basic"
                              label="Entry Fees"
                              variant="outlined"
                              value={value}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Allow empty input or valid numbers including decimals
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  onChange(inputValue);
                                  setEntryFees(inputValue);
                                }
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                              autoComplete="off"
                            />
                          )}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "12px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        {/* {gameCategory ? ( */}
                        <TextField
                          id="outlined-basic"
                          label="Prize Money"
                          variant="outlined"
                          // defaultValue={0}
                          value={`${!entryFees ? 0 : prizeMoney} `}
                          // onChange={(e) => onChange(Number(e.target.value) || "")}
                          // error={!!error}
                          // helperText={error ? error.message : null}
                          // autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {/* ) : ( */}
                        {/* <Controller
                            name="prizeMoney"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Prize money is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-basic"
                                label="Prize Money"
                                variant="outlined"
                                value={prizeMoney}
                                onChange={(e) => onChange(Number(e.target.value) || "")}
                                error={!!error}
                                helperText={error ? error.message : null}
                                autoComplete="off"
                                // InputProps={{
                                //   readOnly: true,
                                // }}
                              />
                            )}
                          /> */}
                        {/* )} */}

                        <Controller
                          name="gameFrequency"
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: "Game frequency is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180 }}
                              options={
                                gameConfig?.gameFrequency?.map((frequency) => ({
                                  label: frequency,
                                })) || []
                              }
                              getOptionLabel={(option) => option?.label}
                              onChange={(event, newValue) => {
                                onChange(newValue?.label || ""); // Use newValue instead of value
                              }}
                              value={
                                value
                                  ? gameConfig?.gameFrequency?.find(
                                      (option) => option?.label === value
                                    )
                                  : undefined
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Game Frequency"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          )}
                        />

                        <Controller
                          name="gameDuration"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Entry fees is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-basic"
                              label="Game Duration"
                              variant="outlined"
                              value={value}
                              onChange={(e) => onChange(Number(e.target.value) || "")}
                              error={!!error}
                              helperText={error ? error.message : null}
                              autoComplete="off"
                            />
                          )}
                        />

                        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "18px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <Controller
                          name="gameType"
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: "Game type is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180 }}
                              options={
                                gameConfig?.gameType?.map((type) => ({
                                  label: type,
                                })) || []
                              }
                              getOptionLabel={(option) => `pick ${option.label}`}
                              onChange={(event, newValue) => {
                                onChange(Number(newValue?.label) || "");
                                //setting type value to state for validating the cryptos count
                              }}
                              value={
                                value
                                  ? gameConfig?.gameType?.find((option) => option.label === value)
                                  : undefined
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Game Type"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          )}
                        />
                        <Controller
                          name="winnerType"
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: "Winner type is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180 }}
                              // getOptionLabel={(option: any) => option.product_name}
                              options={
                                gameConfig?.winnerType?.map((category) => ({
                                  label: category,
                                })) || []
                              }
                              getOptionLabel={(option) => option.label}
                              onChange={(event, newValue) => {
                                onChange(newValue?.label || ""); // Use newValue instead of value
                              }}
                              value={
                                value
                                  ? gameConfig?.winnerType?.find((option) => option.label === value)
                                  : undefined
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Winner Type"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          )}
                        />

                        {/* {gameCategory ? ( */}
                        <Controller
                          name="gameGenerate"
                          control={control}
                          defaultValue={undefined}
                          rules={{ required: "Game generate is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180 }}
                              options={
                                gameConfig?.gameGenerate?.map((generate) => ({
                                  label: generate,
                                })) || []
                              }
                              getOptionLabel={(option) => option?.label}
                              onChange={(event, newValue) => {
                                onChange(newValue?.label || ""); // Use newValue instead of value
                              }}
                              value={
                                value
                                  ? gameConfig?.gameGenerate?.find(
                                      (option) => option?.label === value
                                    )
                                  : undefined
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Game Generate"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          )}
                        />
                        {/* ) : (
                            ""
                          )} */}
                        {/* <Grid
                          container
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"column"}
                        > */}

                        <Box
                          sx={{
                            display: "flex",
                            gap: "18px",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <InputLabel sx={{ marginBottom: -1 }}>
                              <Tooltip
                                title={
                                  "Automatically fills with fake users if actual user participation is below the specified threshold percentage."
                                }
                                placement="top"
                              >
                                <InfoIcon sx={{ fontSize: 20, cursor: "pointer" }} />
                              </Tooltip>
                              {`    User Threshold Auto-Fill`}

                              {userLimit == 0 && (
                                <>
                                  <br />
                                  <Typography fontSize={12} sx={{ ml: 2 }}>
                                    {"   Disable Bot Users"}
                                  </Typography>
                                </>
                              )}

                              {userLimit >= 100 && (
                                <>
                                  <br />
                                  <Typography fontSize={12} sx={{ ml: 2 }}>
                                    {"Start the game using bot users"}
                                  </Typography>
                                </>
                              )}
                            </InputLabel>
                            <Controller
                              name="fup"
                              control={control}
                              defaultValue={0}
                              rules={{
                                required: "User Threshold Auto-Fill percentage is required",
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                // <Box
                                //   sx={{
                                //     display: "flex",
                                //     direction: "flex-column",
                                //   }}
                                // >
                                <FormControl>
                                  {/* <InputLabel sx={{}}>
                                    <InfoIcon />
                                    User Threshold Auto-Fill
                                  </InputLabel> */}

                                  <TextField
                                    sx={{
                                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                          display: "none",
                                        },
                                      "& input[type=number]": {
                                        MozAppearance: "textfield",
                                      },
                                      marginTop: 2,
                                      width: 170,
                                    }}
                                    id="outlined-basic"
                                    // label="User Threshold Auto-Fill"
                                    variant="outlined"
                                    value={value}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Typography sx={{ color: "#FFFFFF" }}>%</Typography>
                                        </InputAdornment>
                                      ),
                                      // startAdornment: (
                                      //   <InputAdornment position="end">
                                      //     <InfoIcon />
                                      //   </InputAdornment>
                                      // ),
                                      inputProps: {
                                        max: 100,
                                        min: userLimit == 0 ? 0 : 50,
                                      },
                                    }}
                                    type="number"
                                    defaultValue={0}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Allow empty input or valid numbers including decimals
                                      if (/^\d*\.?\d*$/.test(inputValue)) {
                                        onChange(inputValue);
                                        setUserLimit(inputValue);
                                        console.log(parseInt(e?.target?.value ?? 0));

                                        if (parseInt(e?.target?.value ?? 0) >= 50) {
                                          debouncedCheckUserAvailability(inputValue, minPlayer);
                                        } else {
                                          if (parseInt(e?.target?.value ?? 0) != 0) {
                                            setFupApiError(
                                              "Value must be greater than or equal to 50"
                                            );
                                          } else {
                                            setFupApiError(null);
                                          }
                                        }
                                      }
                                    }}
                                    error={!!error || !!fupApiError}
                                    helperText={error ? error?.message : fupApiError ?? null}
                                    autoComplete="off"
                                  />
                                </FormControl>
                              )}
                            />
                          </Box>

                          <Box
                            sx={{
                              marginTop: userLimit == 0 || userLimit >= 100 ? 3.8 : 2,
                              // height: "2rem",
                              // alignItems: "end",
                            }}
                          >
                            <Controller
                              name="startTime"
                              control={control}
                              rules={{ required: "Start time is required" }}
                              defaultValue={null}
                              render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                  <InputLabel sx={{ mt: -3 }}>Start Time</InputLabel>
                                  <TimePicker
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setStartTimeError(null); // Reset the error state on value change
                                    }}
                                    value={field.value}
                                    disableClock
                                    clearIcon={false}
                                    format="h:m a"
                                    hourPlaceholder="-"
                                    //

                                    className={`custom-timepicker ${error ? "error" : ""}`}
                                  />

                                  <FormHelperText error={!!error} id="my-helper-text">
                                    {error?.message}
                                  </FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Box>
                        </Box>

                        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "100%",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "red" }}>
                          {error.cryptos ? error.cryptos : ""}
                        </Typography>
                        {/* <Controller
                          name="cryptos"
                          control={control}
                          defaultValue={null}
                          rules={{ required: "Crypto values are required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              multiple
                              id="tags-standard"
                              sx={{ width: { xs: "240px", md: "575px" } }}
                              options={cryptos}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => {
                                // if (reason === "clear" || reason === "removeOption")
                                //   setSelectAll(false);
                                // if (reason === "selectOption" && value?.length === cryptos?.length)
                                //   setSelectAll(true);
                                onChange(newValue ? newValue.map((option) => option._id) : []);
                              }}
                              value={
                                value ? cryptos?.filter((option) => value.includes(option._id)) : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Cryptos"
                                  variant="outlined"
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          )}
                        /> */}
                        <Box mt={2} width={"100%"}>
                          <Controller
                            name="cryptos"
                            control={control}
                            defaultValue={null}
                            rules={{ required: "Crypto values are required" }}
                            render={({ field: { onChange } }) => (
                              <MultiSelect
                                items={cryptos}
                                label="Cryptos"
                                selectAllLabel="Select all cryptos"
                                onChange={onChange}
                              />
                            )}
                          />
                        </Box>
                      </Box>

                      {/* <Demo /> */}
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ textAlign: "center" }}
                          // disabled={!isValid}
                        >
                          Create
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <AlertConfirm
          open={alertOpen}
          title="Create"
          context="Do you want to create the game?"
          onClose={() => setAlertOpen(!alertOpen)}
          onAccept={async () => {
            try {
              // if (userLimit > 0) {
              //   const data = await handleUserAvailability(Number(userLimit));
              //   console.log(userLimit, data);
              //   if (data === false) {
              //     return alert("Failed to check user availability");
              //   }
              // }

              // return;

              if (Number(gamedata?.entryFees) <= 0)
                return alert("Please select entry fees greater than zero!");

              const body = {
                ...gamedata,
                fup: parseInt(gamedata?.fup),
                entryFees: Number(gamedata.entryFees),
                minPlayer: minPlayer,
                maxPlayer: maxPlayer,
                prizeMoney: prizeMoney,
                gamePaymentType: "Paid",
              };

              console.log(body, "BODY ");
              const gameCreate = await createGame(body);
              if (gameCreate) {
                alert("Success! New game has been created");
                setOpen(false);
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
              // --------------------------------------------------
              // } else {
              //   const body = {
              //     ...gamedata,
              //     minPlayer: minPlayer,
              //     maxPlayer: maxPlayer,
              //     gamePaymentType: "Paid",
              //     // positions: data,
              //   };

              //   console.log(body, "BODY 2");

              //   const gameCreate = await createGame(body);
              //   if (gameCreate) {
              //     setOpen(false);
              //     setTimeout(() => {
              //       window.location.reload();
              //     }, 300);
              //     // window.location.reload()
              //   }
              // }
            } catch (error) {
              console.log(error.response.data?.error, "errr");
              alert(error.response.data?.error);
              setAlertOpen(false);
            }
          }}
        />
      </div>
    </>
  );
}

export default GameCreate;
