/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
// import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { getUsers } from "services/api/gameAPI";
import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUsers({ page: page + 1, limit, term });
        console.log(response, "res");
        setUsers(response.data.data.users);
        console.log(response.data.users);
        setCount(response.data.data.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };
  const columns = [
    { label: "username", value: (player) => player.username || "" },
    { label: "email", value: (player) => player.email || "" },
    { label: "Phone", value: (player) => player.phone || "" },
    // { label: "gender", value: (player) => player?.gender || "" },
    { label: "name", value: (player) => player?.name || "" },
  ];
  const downloadExcel = (d) => {
    console.log("d", d);
    const headerRow = columns?.map((column) => ({ value: column.label, type: "string" }));
    const dataRows = d.users?.map((player, search) =>
      columns?.map((column) => ({
        value: column.value(player),
        type: "string",
      }))
    );
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `420 wallet transaction`, sheet: { data } });
  };
  const totalData = async () => {
    const data = await getUsers({ page: page + 1, limit: count, term });

    // setSearch(data);
    console.log(data, "data");
    // setTotal(data);
    await downloadExcel(data.data.data);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {/* {users.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : ( */}
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px -1px",
                    }}
                  >
                    <MDBox margin="20px" pr={1}>
                      <MDInput
                        label="Search user"
                        value={term}
                        size="small"
                        onChange={handletitle}
                      />
                    </MDBox>
                    <MDTypography component="label" style={{ padding: "80px" }}>
                      {/* Add label text here */}
                    </MDTypography>
                    {/* {username === "admin" && ( */}
                    <div style={{ margin: "20px" }}>
                      <MDButton
                        disabled={users?.length === 0}
                        variant="contained"
                        onClick={totalData}
                        color="primary"
                      >
                        Export to Excel
                      </MDButton>
                    </div>
                    {/* )} */}
                  </div>
                  <TableContainer>
                    {users?.length === 0 ? (
                      <>
                        {" "}
                        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                          There are no records at the moment.
                        </div>
                      </>
                    ) : (
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Username</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Phone</StyledTableCell>
                            <StyledTableCell>View</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {users?.map((user) => (
                            <StyledTableRow key={user._id}>
                              <StyledTableCell>{user.username}</StyledTableCell>
                              <StyledTableCell>{user.email}</StyledTableCell>
                              <StyledTableCell>{user.phone}</StyledTableCell>
                              <StyledTableCell>
                                <Link to={`/profile/${user._id}`} state={{ List: user._id }}>
                                  <MDButton color="info">VIEW</MDButton>
                                </Link>
                              </StyledTableCell>
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </>
            {/* )} */}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default Users;
