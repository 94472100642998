/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
import { useNavigate, useParams } from "react-router-dom";

// NewProduct page components
// import { useNavigate } from 'react-router-dom';
import FormField from "components/FormField";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AlertMessage from "components/ErrorMessage";

import { editUser, getUser } from "services/api/gameAPI";
import RHFUploadAvatar from "components/RHTextField/RHFUploadAvatar";

function Socials() {
  let navigate = useNavigate();
  const { id } = useParams();

  let [passengers, setPassangers] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [username, setUsername] = useState(null);
  const [phone, setPhone] = useState(null);
  const [gender, setGender] = useState(null);
  const [location, setLocation] = useState(null);
  const [birthDate, setBirthDate] = React.useState(0);
  const [file, setFile] = React.useState(null);

  useEffect(async () => {
    await getUser(id).then((response) => {
      console.log(response);
      setPassangers(response.data.data);

      setFullName(passengers?.name ?? null);
      setLocation(passengers?.location ?? null);
      setCity(passengers?.city ?? null);
      setEmail(passengers?.email ?? null);
      setLoading(passengers?.location ?? null);
      setGender(passengers?.gender ?? null);
      //  setRegisterNo(passengers?.vehicle?.registerNumber);
      setPhone(passengers?.phone ?? null);

      setLoading(true);
    });
  }, []);

  console.log(passengers, "passengers");

  const submit = async (e) => {
    e.preventDefault();

    const body = {
      name: fullName || passengers?.name,
      email: email || passengers?.email,
      phone: phone || passengers?.phone,
      location: location || passengers?.location,
      gender: gender || passengers?.gender,
    };
    console.log(body);

    const formData = new FormData();

    if (body?.name) formData.append("name", body?.name);
    if (body?.email) formData.append("email", body?.email);
    if (file) formData.append("profilePic", file || ""); // Ensure file is not undefined
    if (body?.phone) formData.append("phone", body?.phone);
    if (body?.location) formData.append("location", body?.location);
    if (body?.gender) formData.append("gender", body?.gender);

    // Log the formData for debugging
    for (const pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    const response = await editUser(id, formData);
    setIsOpen(true);
    setTimeout(() => {
      navigate(-1);
    }, 1200);
  };
  const validateEmail = (inputEmail) => {
    // Regular expression to check for a valid email format
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    if (inputEmail) {
      setEmail(inputEmail);
      setIsValidEmail(validateEmail(inputEmail)); // Check email validity on change
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading === false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: 400,
            flexDirection: "column",
          }}
        >
          <h3>Loading...</h3>
        </div>
      ) : (
        <>
          <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h5" fontWeight="bold">
              Edit Users
            </MDTypography>
            <MDButton variant="gradient" onClick={submit} color="dark">
              Update
            </MDButton>
          </MDBox>
          <MDBox p={2}>
            <MDBox mt={1}>
              <Grid container spacing={2}>
                {}
                <RHFUploadAvatar
                  file={file}
                  setFile={setFile}
                  networkImage={passengers?.profilePic}
                />
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Name"
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    defaultValue={passengers.name}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    defaultValue={passengers.username}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <FormField
                    type="email"
                    label="Email"
                    onChange={handleEmailChange}
                    defaultValue={passengers.email}
                    style={{ borderColor: isValidEmail ? "initial" : "red" }}
                  />
                  {!isValidEmail && <p style={{ color: "red" }}>Invalid email format</p>}
                  {/* Add more form fields and submit button as needed */}
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Location"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    defaultValue={passengers?.location}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Gender"
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    defaultValue={passengers.gender}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Phone Number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    defaultValue={passengers.phone}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <AlertMessage
              open={isOpen}
              setOpen={setAlertOpen}
              severity="Successfully"
              message="Updated"
            />
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
}
export default Socials;
