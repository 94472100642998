import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllReferralList } from "services/api/gameAPI";
import zipcelx from "zipcelx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const Referral = () => {
  const [referralList, setReferralList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");

  useEffect(() => {
    const fetchAllReferralList = async () => {
      const res = await getAllReferralList({ page: page + 1, limit, term });
      console.log(res.data?.data?.data, "referralList");
      setCount(res.data?.data?.totalCount);
      setReferralList(res.data?.data?.data);
      setLoading(false);
    };
    fetchAllReferralList();
  }, [limit, page, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };

  const columns = [
    { label: "User Name", value: (player) => player.code },
    { label: "Reffered User", value: (player) => player.profilesJoined?.length || 0 },
  ];
  const downloadExcel = (d) => {
    console.log("d", d.data);
    const headerRow = columns?.map((column) => ({ value: column.label, type: "string" }));
    const dataRows = d.data.map((player) =>
      columns?.map((column) => ({
        value: column.value(player),
        type: "string",
      }))
    );
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `Cryptochamp Refferel`, sheet: { data } });
  };
  const totalData = async () => {
    const res = await await getAllReferralList({ page: page + 1, limit, term });

    // setSearch(data);
    // console.log(data, "data");
    // setTotal(data);
    await downloadExcel(res.data?.data);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {loading ? (
          <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
        ) : (
          <>
            <Grid container spacing={6} sx={{ marginTop: 3 }}>
              <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 2,
                      // padding: "12px -1px",
                    }}
                  >
                    <MDBox margin="20px" pr={1}>
                      <MDInput
                        label="Search code"
                        value={term}
                        size="small"
                        onChange={handletitle}
                      />
                    </MDBox>
                    <div style={{ margin: "20px" }}>
                      <MDButton variant="contained" onClick={totalData} color="primary">
                        Export to Excel
                      </MDButton>
                    </div>
                    {/* <MDTypography
                      component="label"
                      style={{
                        padding: "40px",
                        color: "grey",
                        fontWeight: 600,
                      }}
                    >
                      Referral Program
                    </MDTypography> */}
                  </div>

                  {referralList.length === 0 ? (
                    <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                      There are no records at the moment.
                    </div>
                  ) : (
                    <>
                      <TableContainer>
                        <Table>
                          <StyledTableHead>
                            <TableRow>
                              <StyledTableCell>REFERRAL BY CODE</StyledTableCell>
                              <StyledTableCell>REFERRED USER</StyledTableCell>
                              <StyledTableCell>VIEW DETAILS</StyledTableCell>
                              {/* Add more table headers as needed */}
                            </TableRow>
                          </StyledTableHead>
                          <TableBody>
                            {referralList?.map((ref, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell>{ref?.code}</StyledTableCell>
                                <StyledTableCell>{ref?.profilesJoined?.length}</StyledTableCell>

                                <StyledTableCell>
                                  <Link
                                    to={`/referralDetails/${ref?._id}`}
                                    state={{ List: ref?._id }}
                                  >
                                    <MDButton color="info">VIEW</MDButton>
                                  </Link>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </Grid>
            <Footer />
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default Referral;
