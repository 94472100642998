import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Card,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  TextField,
  TableContainer,
} from "@mui/material";
import permissionsData from "./permissionsData";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { singleRole } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";
import { EditRole } from "services/api/gameAPI";
let headerAction = [
  { name: "view", value: "read" },
  { name: "add", value: "write" },
  { name: "edit", value: "edit" },
  { name: "delete", value: "delete" },
  { name: "block", value: "block" },
];


permissionsData.sort((a, b) => a.action.length - b.action.length);
function RoleBasedAccessControlEdit() {
  const [userId, setUserId] = useState(null);
  let [alertOpen, setAlertOpen] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [ids, setIds] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [Name, setName] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { id } = useParams();
  const [oldPermissionsData, setOldPermission] = useState([]);
  const fetchPermissionsData = async () => {
    
      setLoading(true);
      const response = await singleRole(id);
      const oldPermissionsData = response.data.permissions;
      await setOldPermission(response.data);

      await setRoleName(response.data.role);
      await setIds(response.data);
      const permissions = oldPermissionsData.map((permission) => {
        const [permissionName, action] = permission.split(".");
        return {
          permissionName,
          actions: [action],
        };
      });

      await setPermissions(permissions);
      console.log(permissions);
      setLoading(false);
    
  };

  useEffect(() => {
    fetchPermissionsData();
  }, []);

  const handlePermissionChange = (permissionName, action) => {
    setPermissions((prevPermissions) => {
      const existingPermissionIndex = prevPermissions.findIndex(
        (p) => p.permissionName === permissionName
      );

      if (existingPermissionIndex !== -1) {
        return prevPermissions
          .map((permission, index) => {
            if (permission.permissionName === permissionName) {
              const actions = permission.actions.includes(action)
                ? permission.actions.filter((a) => a !== action)
                : [...permission.actions.filter((a) => a !== action), action];

              console.log(actions);

              if (actions.length === 0 && index !== 0) {
                console.log(action.length, index);
                // console.log("hello")
                return null;
              }

              return {
                ...permission,
                actions,
              };
            }

            return permission;
          })
          .filter(Boolean);
      } else {
        const newPermission = {
          permissionName,
          actions: [action],
        };

        return [...prevPermissions, newPermission];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      role: roleName,
      permissions: [],
    };
    permissions.forEach((permission) => {
      permission.actions.forEach((action) => {
        const permissionText = `${permission.permissionName}.${action}`;
        payload.permissions.push(permissionText.trim());
      });
    });
    await setUserId(payload);
    setAlertOpen(true);
  
  };
  // const userHandle = (e) => {
  //   e.preventDefault ()
  //   setAlertOpen(true);
  //   setUserId(id);
  // };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <label style={{ color:"white", margin: "12px" }}>Role name</label>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    defaultValue={roleName}
                    placeholder="Input role name"
                    onChange={(e) => setRoleName(e.target.value)}
                    helperText={
                      <div style={{color:"white"}}>
                        Double-click to uncheck the value.
                        <br />
                      </div>
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "40px 0px",
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead style={{ display: "table-header-group" }}>
                      <TableRow >
                        <TableCell style={{color:"white"}}>Functionalities/Name</TableCell>
                        {headerAction.map((e) => (
                          <TableCell style={{color:"white"}} key={e?.name}>
                            <p>{e.name && e.name.charAt(0).toUpperCase() + e.name.slice(1)}</p>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {permissionsData.map((p,index) => (
                       <TableRow key={`${p._id}-${index}`}>
                          <TableCell  style={{ color: "white" }}>{p.name}</TableCell>
                          {headerAction?.map((e) => (
                            <TableCell key={e?.value}>
                              <div
                                key={`${p._id}-${e.value}`}
                                style={{ display: p.action.includes(e.value) ? "flex" : "none" }}
                              >
                                <Checkbox
                                  checked={permissions.some(
                                    (permission) =>
                                      permission.permissionName === p.name &&
                                      permission.actions.includes(e.value)
                                  )}
                                  onChange={() => handlePermissionChange(p.name, e.value)}
                                />
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "20px" }}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
      <AlertConfirm
        open={alertOpen}
        title="Delete"
        context="Do you want to Edit ?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const responseMessage = await EditRole(id, userId);
            console.log(responseMessage);
            navigate("/Role");
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </DashboardLayout>
  );
}

export default RoleBasedAccessControlEdit;
