/**

=========================================================

* Crypto Champs React - v2.1.0

=========================================================

 

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)

 

Coded by www.creative-tim.com

 

 =========================================================

 

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components

import React, { useState, useEffect } from "react";

// import axios from "axios";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import { styled } from "@mui/system";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

import MDBox from "components/MDBox";

import MDInput from "components/MDInput";

// import MDButton from "components/MDButton";

import { Link } from "react-router-dom";

import { rewardData } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,

  borderBottom: "none",
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(even)": {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function RewardTable() {
  const [users, setUsers] = useState([]);

  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(5);

  const [count, setCount] = useState(0);

  const [term, setTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await rewardData({ page: page + 1, limit, term });

        setUsers(response.data.results);

        await console.log(response);

        setCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);

    setPage(0);
  };

  const handletitle = (e) => {
    setTerm(e.target.value);

    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox margin="20px" pr={1}>
              <MDInput label="Search..." value={term} size="small" onChange={handletitle} />
            </MDBox>

            <TableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Transferred Token</StyledTableCell>

                    {/* <StyledTableCell>admin</StyledTableCell> */}

                    <StyledTableCell>User </StyledTableCell>

                    <StyledTableCell>Created At </StyledTableCell>
                    <StyledTableCell>Game </StyledTableCell>

                    {/* Add more table headers as needed */}
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {users?.map((user) => (
                    <TableRow key={user?._id}>
                      <StyledTableCell>{user?.tokenCount}</StyledTableCell>

                      {/* <StyledTableCell>

                        <Link

                          to={`/profile/${user.adminId._id}`}

                          state={{ List: user._id }}

                          style={{ color: "white", textDecoration: "none" }}

                        >

                          {user.adminId.username}

                        </Link>

                      </StyledTableCell> */}

                      <StyledTableCell>
                        <Link
                          to={`/profile/${user.userId?._id}`}
                          state={{ List: user?._id }}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {user?.userId?.username}
                        </Link>
                      </StyledTableCell>

                      <StyledTableCell>{user?.createdAt}</StyledTableCell>

                      <StyledTableCell>{user?.populatedGame[0]?.gameName}</StyledTableCell>

                      {/* Add more table cells for other user properties */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={count}
            style={{ color: "white" }}
            rowsPerPage={limit}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
        </Grid>
      </Grid>

      <Footer />
    </DashboardLayout>
  );
}

export default RewardTable;
