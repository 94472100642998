import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie'
import {
  Card,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  TextField,
  Grid,
} from "@mui/material";
import { useNavigate,useParams } from 'react-router-dom';
import permissionsData from "./permissionsData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { singleRole } from "services/api/gameAPI";
import { UN_AUTH_STATUS } from "lib/axiosConfig";

let headerAction = [ { "name": "view", "value": "read" },{ "name": "add", "value": "write" }, { "name": "edit", "value": "edit" },{ "name": "delete", "value": "delete" }, { "name": "block", "value": "block" }]

permissionsData.sort((a, b) => a.action.length - b.action.length);
function RoleBasedAccessControlView() {
  const [roleName, setRoleName] = useState(null);
  const [ids, setIds] = useState(null)
  const [permissions, setPermissions] = useState([]);
  const [Name, setName] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldPermissionsData, setOldPermission] = useState([]);
  const fetchPermissionsData = async () => {
    try {
      setLoading(true)
      const response = await singleRole(id)
      const oldPermissionsData = response.data.permissions;
      await setOldPermission(response.data);

      await setRoleName(response.data.role)
      await setIds(response.data)
      const permissions = oldPermissionsData.map((permission) => {
        const [permissionName, action] = permission.split('.');
        return {
          permissionName,
          actions: [action],
        };
      });

      await setPermissions(permissions);
      console.log(permissions);
      setLoading(false)
    } catch (error) {
      alert(`${UN_AUTH_STATUS}`);
          window.history.back();
      console.error("Error fetching permissions:", error);
    }
  };



  useEffect(() => {
    fetchPermissionsData()

  }, []);

  const handlePermissionChange = (permissionName, action) => {
    setPermissions((prevPermissions) => {
      const existingPermissionIndex = prevPermissions.findIndex(
        (p) => p.permissionName === permissionName
      );

      if (existingPermissionIndex !== -1) {
        return prevPermissions.map((permission, index) => {
          if (index === existingPermissionIndex) {
            // Check if the actiond is the only one in the array being change
            if (permission.actions.length === 1 && permission.actions.includes(action)) {
              // If so, remove the entire permission from the list
              return null;
            } else {
              // Otherwise, remove only the action from the array
              const actions = permission.actions.filter((a) => a !== action);
              return {
                ...permission,
                actions,
              };
            }
          }
          return permission;
        }).filter(Boolean); // Filter out any null permissions
      } else {
        return [
          ...prevPermissions,
          {
            permissionName,
            actions: [action],
          },
        ];
      }
    });
  };

  return (
    <DashboardLayout>
      
      <DashboardNavbar />
      <div style={{ display: "flex", flexDirection: "column"}}>
        {loading ? <div>Loading...</div> : <div>
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <label style={{ color:"white", margin: "12px" }}>Role name</label>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  defaultValue={roleName}
                  placeholder="Input role name"
                  onChange={(e) => setRoleName(e.target.value)}
                  disabled
                />
              </div>
              <Grid style={{ marginTop: '-16px' }}>
                <Button variant="contained" color="secondary" type="submit" style={{ margin: "20px" }}
                  onClick={() => {
                    navigate(`/Role/Edit/${id}`,{state: oldPermissionsData});
                  }}
                >Edit</Button>
              </Grid>            </div>

            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0px",
              }}
            >
              <Table>
                <TableHead style={{ display: "table-header-group"}}> 
                  <TableRow>
                  <TableCell style={{color:"white"}}> Functionalities/Name</TableCell>
{
  headerAction.map((e) => (
    <TableCell style={{color:"white"}} key={e?.name}>
      <p>{e.name && e.name.charAt(0).toUpperCase() + e.name.slice(1)}</p>
    </TableCell>
  ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissionsData.map((p,index) => (
                     <TableRow key={`${p._id}-${index}`}>
                      <TableCell  style={{ color: "white" }}>{p.name}</TableCell>
                      {headerAction?.map((e) => (
                        <TableCell key={e?.value}>
                          <div
                            key={`${p._id}-${e.value}`}
                            style={{ display: p.action.includes(e.value) ? "flex" : "none" }}
                          >
                            <Checkbox
                              checked={permissions.some(
                                (permission) =>
                                  permission.permissionName === p.name &&
                                  permission.actions.includes(e.value)
                              )}
                              onChange={() => handlePermissionChange(p.name, e.value)}
                              disabled
                            />
                          </div>
                        </TableCell>
                      ))}

                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              
            </div>
          </div>
        </div>}



      </div>
      
    </DashboardLayout>
  );
}

export default RoleBasedAccessControlView;
