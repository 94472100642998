import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  // TablePagination,
  List,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { ledgerData, locationAPI } from "services/api/gameAPI";
import { searchUser } from "services/api/gameAPI";
import { useStyles } from "layouts/tokenDistribution";
import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import ModalBox from "./modal";
import AlertConfirm from "components/AlertConfirm";
import { deleteLocation } from "services/api/gameAPI";
import { getGameConfig } from "services/api/gameAPI";
import { deleteGameType } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function GameType() {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  let [userId, setUserId] = useState("");
  const [types, setTypes] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [open, setOpen] = React.useState(true);

  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getGameConfig();
        console.log(response.data?.data?.gameType, "gameType");
        setTypes(response.data?.data?.gameType);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };
  function handleSuggestionClick(suggestion) {
    setQuery(suggestion?.username);
    console.log(suggestion);
    //setTitleName(suggestion?.username)
    setTerm(suggestion?._id);

    let url = ledgerData({ page: page + 1, limit, term });
    const res = fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // setSearch(data);
        console.log(data, "data");
        setTerm(data?.results);
        setCount(data?.count);
      });

    setSuggestions([]);
  }

  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  //   setPage(0);
  // };
  // const handleTitle = async (e) => {
  //   // setTerm(e.target.value);
  //   // setPage(0);
  //   const newQuery = e.target.value;
  //   if (newQuery === -null) {
  //     ledgerData(page, limit);
  //   }
  //   setQuery(newQuery);
  //   const newSuggestions = await fetchSuggestions(newQuery);
  //   console.log(newSuggestions);
  //   setSuggestions(newSuggestions);
  // };
  // async function fetchSuggestions(query) {
  //   const response = await searchUser(query);
  //   // const data = await response.json();

  //   return response.data;
  // }

  const userHandle = (type) => {
    console.log(typeof type);

    setUserId(type);
    setAlertOpen(true);
  };

  console.log(types);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ModalBox />

      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {types?.length === 0 ? (
              <div
                style={{
                  color: "#FFF",
                  paddingLeft: "35%",
                  paddingBottom: "20%",
                  marginTop: "50px",
                }}
              >
                There are no records at the moment.
              </div>
            ) : (
              <>
                <Grid item xs={12}>
                  <Card>
                    {query === "" ? (
                      ""
                    ) : (
                      <div id="search-card" className={classes.searchCard}>
                        <Paper>
                          <List id="suggestion-list">
                            {suggestions.map((suggestion) => (
                              <ListItem
                                key={suggestion._id}
                                button
                                className={classes.suggestionItem}
                                onClick={() => handleSuggestionClick(suggestion)}
                              >
                                {suggestion?.username}
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      </div>
                    )}

                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Game Type</StyledTableCell>
                            {/* <StyledTableCell>Created</StyledTableCell>
                              <StyledTableCell>Updated</StyledTableCell> */}
                            <StyledTableCell>Delete</StyledTableCell>

                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {types?.map((type) => (
                            <StyledTableRow key={type}>
                              <StyledTableCell>Pick {type}</StyledTableCell>
                              {/* <StyledTableCell>
                                  {new Date(user?.createdAt).toLocaleString("en-US", {
                                    timeZone: "America/New_York",
                                  })}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {new Date(user?.updatedAt).toLocaleString("en-US", {
                                    timeZone: "America/New_York",
                                  })}
                                </StyledTableCell> */}

                              <StyledTableCell>
                                <MDButton color="error" onClick={() => userHandle(type)}>
                                  delete
                                </MDButton>
                              </StyledTableCell>
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
                {/* <Grid item xs={12}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 15]}
                      component="div"
                      count={count}
                      rowsPerPage={limit}
                      page={page}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                    />
                  </Grid> */}
              </>
            )}
            <AlertConfirm
              open={alertOpen}
              title="Delete"
              context="Are you sure you want to delete ?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                setAlertOpen(!alertOpen);
                const data = {
                  gameType: userId,
                };
                const res = await deleteGameType(data);
                if (res) {
                  alert("Success! Game Type deleted.");
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 80);
                }
              }}
            />
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default GameType;
