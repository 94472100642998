/* eslint-disable import/prefer-default-export */
import api from "lib/axiosConfig";
import { upload } from "lib/axiosConfig";

export const getGameConfig = async () => await api.get("/game-config/get");
export const createGameType = (data) => api.post("/game-config/addGameType", data);
export const deleteGameType = async (data) => await api.put("/game-config/deleteGameType", data);
export const getCryptos = async () => await api.get("/crypto/get");
export const createGame = async (data) => await api.post("/admin/game/create", data);
export const editGame = async (id, data) => await api.put(`/admin/game/update/${id}`, data);
export const deleteGame = async (id) => await api.delete(`/admin/game/delete/${id}`);

export const getGameList = async ({ page, limit }) =>
  await api.get(`/admin/game/getAll?page=${page}&limit=${limit}`);
export const getGameInstances = async ({ id, page, limit }) =>
  await api.get(`/admin/game-instance/get/${id}?page=${page}&limit=${limit}`);

export const cancelGameInstace = async (id) =>
  await api.post(`/admin/game-instance/cancelGame/${id}`);

export const stopGeneratingGame = async (body) => await api.put("/admin/game/gameGenerate", body);

export const logInModule = async (data) => await api.post("/admin/login", data);

export const locationAPI = async () => await api.get(`/access-location`);
export const createLocation = async (data) => await api.post(`/access-location`, data);
export const deleteLocation = async (id) => await api.delete(`/access-location/${id}`);
export const ledgerData = async ({ page, limit, term }) =>
  await api.get(`/ledger/findByPagination?page=${page}&limit=${limit}&title=${term}`);

export const SelectedCryptosData = async ({ page, limit, term }) =>
  await api.get(`/admin/crypto/getAll?isDisabled=false&page=${page}&limit=${limit}&title=${term}`);

export const fetchCryptosData = async ({ page, limit, term }) =>
  await api.get(`/crypto/binanceMarketing?page=${page}&limit=${limit}&title=${term}`);

export const addCryptoData = async (body) => await api.post(`/crypto/addCryptos`, body);

export const ledger24Data = async ({ page, limit, term }) =>
  await api.get(
    `/ledger/findByPagination?page=${page}&limit=${limit}&title=${term}&last24Hours=true`
  );
export const walletData = async ({ page, limit, term, last24Hours }, body) =>
  await api.post(
    `/wallet-transaction/walletTransaction/findByPagination?page=${page}&limit=${limit}&title=${term}&last24Hours=${last24Hours}`,
    body
  );

export const walletData24Days = async ({ page, limit, term }, body) =>
  await api.post(
    `/wallet-transaction/walletTransaction/findByPagination?page=${page}&limit=${limit}&last24Hours=true`,
    body
  );

//merkele
export const getMerkle = async ({ page, limit, term }) =>
  await api.get(`/merkle-tree/getList?page=${page}&limit=${limit}`);

//post to create tree

export const createTree = async (data) => await api.post("/merkle-tree/generateHash", data);

export const rewardData = async ({ page, limit, term }) =>
  await api.get(`/rewards/findByPagination?page=${page}&limit=${limit}&title=${term}`);

export const getUser = async (id) => await api.get(`/admin/users/getById/${id}/`);
export const getUsers = async ({ page, limit, term }) =>
  await api.get(`/admin/users/users?page=${page}&limit=${limit}&searchTerm=${term}`);
export const getCustomUsers = async ({ page, limit, term, customUser }) =>
  await api.get(
    `/admin/users/users?page=${page}&limit=${limit}&searchTerm=${term}&customUser=true`
  );
export const editUser = async (id, data) =>
  await api.put(`/admin/users/update/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const removeProfilePhoto = async (id) =>
  await api.put(`/admin/users/removeProfilePic/${id}`);
export const sendReward = async (data) => await api.post("/rewards/sendAdmin", data);
export const searchUser = async (query) => await api.get(`/users/search-user?title=${query}`);
export const getByUsername = async (username) => await api.get(`admin/users/username/${username}`);
export const createUser = async (data) =>
  await api.post(`admin/users/create`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const calCustomUser = async (data) => await api.post(`admin/users/avCustomUser`, data);
export const disableFakeUser = async (id) => await api.post(`admin/users/disable/${id}`, {});
export const deleteFakeUser = async (id) => await api.delete(`admin/users/delete/${id}`);

export const createRole = async (data) => await api.post("/user-roles", data);

export const getRole = async () => await api.get("/user-roles");

export const getTransaction = async ({ page, limit }) =>
  await api.get(`/transaction/getAll?page=${page}&limit=${limit}`);

export const getBlockChainTransaction = async ({ page, limit }) =>
  await api.get(`/blockchainTransaction/getAll?page=${page}&limit=${limit}`);

export const getBlockChainWithdrawalTransactions = async ({ page, limit }) =>
  await api.get(
    `/blockchainTransaction/getAll?page=${page}&limit=${limit}&paymentMethod=CHAMPS&verified=false`
  );

export const convertUsdcAndWeth = async (data) =>
  await api.post(`admin/blockchainTransaction/convertUsdcAndWeth`, data);

export const getDepositBlockChainTransaction = async ({ page, limit }) =>
  await api.get(
    `/blockchainTransaction/getAll?page=${page}&limit=${limit}&transactionType=Deposit`
  );

export const updateBlockchainTransaction = async (body) =>
  await api.post("/blockchainTransaction/update-verified", body);

export const getBlockChianConfig = async () => await api.get("/admin/blockChainConfig/getAll");
export const CreateBlockChianConfig = async (data) =>
  await api.post("/admin/blockChainConfig/create", data);
export const createBlockChianConfigPaymentMethod = async (id, data) =>
  await api.put(`/blockChainConfig/paymentMethod/${id}`, data);

export const CreateReferralConfig = async (data) =>
  await api.post("/admin/configData/updateInvitation", data);

export const getReferralConfig = async (data) => await api.get("/admin/configData/get", data);

// export const updateMultipleIds = async (body) =>
//   await api.post("/transaction/update-verified", body);

// export const updateMultipleIds = async (body) =>
//   await api.post("/blockchainTransaction/update-verified", body);

export const updateMultipleIds = async (body) =>
  await api.post("/blockchainTransaction/update-verified/all", body);

export const deleteRoot = async (id) => await api.delete(`/merkle-tree/${id}`);

export const singleRole = async (id) => await api.get(`/user-roles/${id}`);

export const deleteRole = async (id) => await api.delete(`/user-roles/${id}`);

export const EditRole = async (id, data) => await api.put(`/user-roles/${id}`, data);

export const createAdmin = async (data) => await api.post("/admin/create", data);

export const getAdmin = async () => await api.get("/admin/admin");

export const singleAdmin = async (id) => await api.get(`/admin/${id}`);

export const editAdmin = async (id, data) => await api.put(`/admin/${id}/update`, data);

export const deleteAdmin = async (id) => await api.delete(`/admin/delete/${id}`);

// export const addInstance = async (id) => await api.post(`/admin/game-instance/create/${id}`);

export const addInstance = async (body) => await api.post("/admin/game-instance/create", body);

//leader board
export const createLeaderBoard = async (id) =>
  await api.post(`/admin/leaderBoard/regenerate/${id}`);

// pay out stucture

export const createPayoutStructure = async (data) =>
  await api.post("/admin/payout-structure/create", data);

export const getAllPayoutStructure = async ({ page, limit }) =>
  await api.get(`/admin/payout-structure/getAll?page=${page}&limit=${limit}`);
export const getOnePayoutStructure = async (id) => await api.get(`/payout-structure/`);
export const updatePayoutStructure = async (id, body) =>
  await api.put(`/admin/payout-structure/update/${id}`, body);
export const payoutStructureFindByName = async (body) =>
  await api.post(`admin/payout-structure/findByName`, body);
export const checkPayoutIsExistInGame = async (id) =>
  await api.get(`/admin/payout-structure/isExistInGame/${id}`);
export const deletePayoutStructure = async (id) =>
  await api.delete(`/admin/payout-structure/delete/${id}`);

export const uploadSliderImage = async (data) => await upload.put("/home-page/uploadSlider", data);

export const getLandingPageData = async () => await api.get("/home-page/get");

export const deleteLandingImage = async (sliderImage) => {
  await api.delete("/home-page/deleteSlider", {
    data: {
      sliderImage,
    },
  });
};

export const getClosedGameInstance = async ({ page, limit }) =>
  await api.get(
    `/admin/blockchainTransaction/game-instance/getClosedGame?page=${page}&limit=${limit}`
  );

export const createBlockchainTransaction = async (body) =>
  await api.post("/admin/blockchainTransaction/create", body, {});

// Faq
export const getAllFaqs = async () => await api.get(`/faq/getAll`);
export const createFaq = async (body) => await api.post("/faq/create", body, {});
export const updateFaq = async (id, body) => await api.put(`/faq/update/${id}`, body, {});
export const deleteFaq = async (id) => await api.delete(`/faq/delete/${id}`);

// Rules
export const getAllRules = async () => await api.get(`/admin/game-rules/getAll`);
export const getBiggestWinnerRules = async () => await api.get(`/admin/game-rules/getGameRules`);
export const getBiggestLoserRules = async () =>
  await api.get(`/admin/game-rules/getGameRules?winnerType=Biggest Loser&gameCategory=Multiplayer`);
export const createRules = async (data) => await api.post(`/game-rules/create`, data);
export const updateRules = async (id, data) => await api.put(`/game-rules/update/${id}`, data);
export const deleteRule = async (id) => await api.delete(`/admin/game-rules/delete/${id}`);

// Referral Program
export const getAllReferralList = async ({ page, limit, term }) =>
  await api.get(`/admin/referral-program/getAll?page=${page}&limit=${limit}&searchText=${term}`);
export const getAllReferralDetails = async ({ id, page, limit }) =>
  await api.get(`/admin/referral-program/getById/${id}?page=${page}&limit=${limit}`);

export const getActiveAndInActieCryptos = async ({ page, limit }) =>
  await api.get(`/admin/crypto/checkCryptos?page=${page}&limit=${limit}`);

export const removeInactiveCryptos = async ({ id }) =>
  await api.post(`/admin/crypto/isDisabled/${id}`);

export const getNotificationCount = async () => await api.get("/admin/crypto/inActive");

export const getTotalPlayedGamesCount = async (id) =>
  await api.get(`/game-instance/total-played-game/${id}`);
