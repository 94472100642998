// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
// import { getUsers } from "services/api/gameAPI";
// import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import { getAllPayoutStructure } from "services/api/gameAPI";
import ModalBox from "./modal";
import PayoutStructureView from "./structureView";
import PayoutStructureEdit from "./edit";
import AlertConfirm from "components/AlertConfirm";
import { deletePayoutStructure } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function Users() {
  const [paylodStructure, setPaylodStructure] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllPayoutStructure({ page: page + 1, limit });
        console.log(response, "res");
        setPaylodStructure(response.data?.data?.payoutStructure);
        console.log(paylodStructure);
        setCount(response.data?.data?.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleDelete = (row) => {
    setDeleteAlertOpen(!deleteAlertOpen);
    setSelectedRow(row);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6} sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Card sx={{ padding: 2 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px -1px",
                  }}
                >
                  <MDTypography
                    component="label"
                    style={{
                      padding: "40px",
                      color: "grey",
                      fontWeight: 600,
                    }}
                  >
                    Payout Structure
                  </MDTypography>

                  <div style={{ margin: "20px" }}>
                    <ModalBox />
                  </div>
                </div>
                {paylodStructure.length === 0 ? (
                  <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                    There are no records at the moment.
                  </div>
                ) : (
                  <>
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Minimum Player</StyledTableCell>
                            <StyledTableCell>Maximum Players</StyledTableCell>
                            <StyledTableCell>Structure</StyledTableCell>
                            <StyledTableCell>Details</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {paylodStructure?.map((user) => (
                            <StyledTableRow key={user._id}>
                              <StyledTableCell>{user?.name}</StyledTableCell>
                              <StyledTableCell>{user.minPlayer}</StyledTableCell>
                              <StyledTableCell>{user.maxPlayer}</StyledTableCell>

                              <StyledTableCell>
                                {/* <Link to={`/profile/${user._id}`} state={{ List: user._id }}> */}
                                {/* <MDButton color="info">VIEW</MDButton> */}
                                <PayoutStructureView data={user.structure} />
                                {/* </Link> */}
                              </StyledTableCell>
                              <StyledTableCell>
                                <PayoutStructureEdit props={user} />
                              </StyledTableCell>
                              <StyledTableCell>
                                <Button
                                  variant="contained"
                                  sx={{ marginBottom: "20px", textTransform: "none" }}
                                  onClick={() => {
                                    handleDelete(user);
                                  }}
                                  disabled={user?.isExistInGame ?? false}
                                >
                                  Delete
                                </Button>
                              </StyledTableCell>
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <AlertConfirm
                      open={deleteAlertOpen}
                      title="Delete"
                      context="Are you sure want to delete this payout?"
                      onClose={() => setDeleteAlertOpen(!deleteAlertOpen)}
                      onAccept={async () => {
                        try {
                          // console.log(selectedRow);
                          const response = await deletePayoutStructure(selectedRow?._id);
                          // console.log(response?.data);

                          if (response?.data?.data) {
                            alert("Payout structure deleted successfully");
                            setTimeout(() => {
                              window.location.reload();
                            }, 300);
                          }
                        } catch (error) {
                          if (error?.response?.data?.error) {
                            alert(error?.response?.data?.error);
                          } else {
                            alert("Something went wrong. Please try again.");
                          }
                        }
                      }}
                    />
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={count}
                style={{ color: "white" }}
                rowsPerPage={limit}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
              />
            </Grid>
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default Users;
