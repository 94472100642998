import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  //   TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import MDBox from "components/MDBox";
import { getGameInstances } from "services/api/gameAPI";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import AlertConfirm from "../../components/AlertConfirm/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { cancelGameInstace } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function ViewInstances() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  //   const gameName = queryParams.get("name");
  const id = queryParams.get("id");
  const [gameData, setGameData] = useState([]);
  let [alertOpen, setAlertOpen] = useState(false);
  const [instanceId, setInstanceId] = useState("");
  const [gameMode, setGameMode] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchGameIntacesData = async (_id) => {
      const games = await getGameInstances({ id: _id, page: page + 1, limit });
      setCount(games.data.data?.totalCount);
      setGameData(games.data.data);
    };
    fetchGameIntacesData(id);
  }, [id, limit, page]);

  const createInstance = (id, mode) => {
    setInstanceId(id);
    setGameMode(mode);
    setAlertOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <IconButton aria-label="delete" color="secondary" onClick={() => navigate(-1)}>
        <ArrowBackIcon color="#fff" />
      </IconButton>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item>
            <Card>
              <MDBox margin="20px" pr={1}></MDBox>
              <TableContainer sx={{ maxWidth: 800 }}>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Game Instance Id</StyledTableCell>
                      <StyledTableCell>Start Time</StyledTableCell>
                      <StyledTableCell>End Time </StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      {/* <StyledTableCell>Leader Board</StyledTableCell> */}

                      <StyledTableCell>Cancel Refund </StyledTableCell>

                      {/* <StyledTableCell>Details</StyledTableCell> */}

                      {/* Add more table headers as needed */}
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {gameData?.gameInstance?.map((instance) => (
                      <StyledTableRow key={instance._id}>
                        <StyledTableCell>
                          {instance._id.substring(instance._id.length - 7).toUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell>{instance.startTime}</StyledTableCell>
                        <StyledTableCell>{instance.endTime}</StyledTableCell>
                        <StyledTableCell>{instance.status.toUpperCase()}</StyledTableCell>
                        {/* <StyledTableCell>
                          <Button variant="contained" sx={{ color: "white" }}>
                            VIEW
                          </Button>
                        </StyledTableCell> */}

                        {/* Add more table cells for other user properties */}
                        {/* <StyledTableCell>
                <Button variant="contained" sx={{ color: "white",textTransform:"none" }} onClick={()=>{ setInstanceId(instance._id); setAlertOpen(true)}} >
                      Create
                    </Button>
                </StyledTableCell> */}
                        <StyledTableCell>
                          <Button
                            onClick={() =>
                              createInstance(instance._id, instance?.game?.gameGenerate)
                            }
                            variant="contained"
                            sx={{ color: "white" }}
                            disabled={
                              instance.status === "canceled " || instance.status === "closed"
                                ? true
                                : false
                            }
                          >
                            Cancel
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                style={{ color: "white" }}
                count={count}
                rowsPerPage={limit}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <AlertConfirm
        open={alertOpen}
        title="Create"
        context="Do you want to create the leader board ?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            console.log(instanceId);
            const response = await createLeaderBoard(instanceId);

            window.location.reload(false);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }}
      /> */}

      <AlertConfirm
        isSubmitting={isSubmitting}
        open={alertOpen}
        title="Cancel and Refund"
        context="Do you want to cancel the game ?"
        subText={
          gameMode === "Frequently" ? "Frequently game will change the game mode to One Time." : ""
        }
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          setIsSubmitting(true);
          try {
            // console.log(instanceId);
            // console.log(gameMode);
            const response = await cancelGameInstace(instanceId);
            if (response) {
              alert("Success! The instance has been canceled.");
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }
          } catch (error) {
            if (error?.response?.data?.error?.message) {
              alert(error?.response?.data?.error?.message);
            } else {
              alert("Oops! Unable to cancel the instance. Please try again.");
            }
            console.error("An error occurred:", error);
          }
          setIsSubmitting(false);
        }}
      />
    </DashboardLayout>
  );
}

export default ViewInstances;
