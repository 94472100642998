/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  List,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import { ledgerData, locationAPI } from "services/api/gameAPI";
import { searchUser } from "services/api/gameAPI";
import { useStyles } from "layouts/tokenDistribution";
import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import ModalBox from "./modal";
import AlertConfirm from "components/AlertConfirm";
import { deleteLocation } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function BlockAccess() {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  let [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [open, setOpen] = React.useState(true);

  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await locationAPI();
        setUsers(response.data);
        console.log(response);
        setCount(response.data.count);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  function handleSuggestionClick(suggestion) {
    setQuery(suggestion?.username);
    console.log(suggestion);
    //setTitleName(suggestion?.username)
    setTerm(suggestion?._id);

    let url = ledgerData({ page: page + 1, limit, term });
    const res = fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // setSearch(data);
        console.log(data, "data");
        setTerm(data?.results);
        setCount(data?.count);
      });

    setSuggestions([]);
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handleTitle = async (e) => {
    // setTerm(e.target.value);
    // setPage(0);
    const newQuery = e.target.value;
    if (newQuery == -null) {
      ledgerData(page, limit);
    }
    setQuery(newQuery);
    const newSuggestions = await fetchSuggestions(newQuery);
    console.log(newSuggestions);
    setSuggestions(newSuggestions);
  };
  async function fetchSuggestions(query) {
    const response = await searchUser(query);
    // const data = await response.json();

    return response.data;
  }
  const columns = [
    { label: "Transfer token", value: (player) => player.transferedT4lToken || "" },
    {
      label: "Created at",
      value: (player) =>
        new Date(player?.created).toLocaleString("en-US", { timeZone: "America/New_York" }) || "",
    },
    { label: "Type of transaction ", value: (player) => player.types || "" },
    {
      label: "Debited from",
      value: (player) => (player?.fromAdmin == true ? "Admin" : "User" || ""),
    },
    { label: "Credited To", value: (player) => player?.from?.username || "" },
  ];
  const downloadExcel = (d) => {
    const headerRow = columns.map((column) => ({ value: column.label, type: "string" }));
    const dataRows = d?.map((player, search) =>
      columns.map((column) => ({
        value: column.value(player),
        type: "string",
      }))
    );
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `420 wallet transaction`, sheet: { data } });
  };
  const totalData = async () => {
    const data = await ledgerData({ page: page + 1, limit: count, term });

    // setSearch(data);
    console.log(data, "data");
    // setTotal(data);
    await downloadExcel(data.data.results);
  };
  const userHandle = (id) => {
    
    setAlertOpen(true)
    setUserId(id)
  console.log(id)

  }
  const latestData = async () => {
    setTrigger(!trigger);
    console.log(trigger);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? <div style={{ color: '#FFF', paddingLeft: '35%', paddingBottom: '20%' }}>Loading...</div> :
        <>
          <Grid container spacing={6}>
            {users.length === 0 ? <div style={{ color: '#FFF', paddingLeft: '35%', paddingBottom: '20%',marginTop:"50px" }}>There
              are no records at the moment.</div> :
              <>
                <Grid item xs={12}>
                  <Card>
           
                    {query === "" ? (
                      ""
                    ) : (
                      <div id="search-card" className={classes.searchCard}>
                        <Paper>
                          <List id="suggestion-list">
                            {suggestions.map((suggestion) => (
                              <ListItem
                                key={suggestion._id}
                                button
                                className={classes.suggestionItem}
                                onClick={() => handleSuggestionClick(suggestion)}
                              >
                                {suggestion?.username}
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      </div>
                    )}

                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Region</StyledTableCell>
                            <StyledTableCell>Created</StyledTableCell>
                            <StyledTableCell>Updated</StyledTableCell>
                            <StyledTableCell>Delete</StyledTableCell>

                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {users?.map((user) => (
                            <StyledTableRow key={user._id}>
                              <StyledTableCell>{user.country}</StyledTableCell>
                              <StyledTableCell>
                                {new Date(user?.createdAt).toLocaleString("en-US", {
                                  timeZone: "America/New_York",
                                })}
                              </StyledTableCell>
                              <StyledTableCell>
                                {new Date(user?.updatedAt).toLocaleString("en-US", {
                                  timeZone: "America/New_York",
                                })}
                              </StyledTableCell>
                     
                              <StyledTableCell>
                                <MDButton color="error" onClick={() => userHandle(user?._id)}>
                                  delete
                                </MDButton>
                      
                              </StyledTableCell>
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
                {/* <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Grid> */}
              </>
            }
            <AlertConfirm
              open={alertOpen}
              title="Delete"
              context="Are you sure you want to delete ?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                setAlertOpen(!alertOpen)
                setTimeout(() => {
                  deleteLocation(userId).then((response) => {
                    console.log(response)
                    window.location.reload(false);
                  }).then(
                    setIsOpen(true)
                  )
                }, 80);
                
              }}
            />
          </Grid>
          <ModalBox />
          <Footer />
        </>}
    </DashboardLayout>
  );
}
export default BlockAccess;
