/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
// import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@mui/material";
import { Box, Container, styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
import { getMerkle } from "services/api/gameAPI";
// import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import { ethers } from "ethers";
import TT4LToken from "../../Contract/TT4L.json";
import { deleteRoot } from "services/api/gameAPI";
import zipcelx from "zipcelx";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));
export const T4Address = "0xF083b8d6741c2c12c13864E94Ad05F697f315BAB";

function Users() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState(0);
  const [contractInfo, setContractInfo] = useState({
    address: "-",
    tokenName: "-",
    tokenSymbol: "-",
    totalSupply: "-",
  });
  const [term, setTerm] = useState(
    "0xaed1f6221c4920f49d786e79f4fff33da5646fa1464ed3e42bb3644c9a6c8d6f"
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMerkle({ page: page + 1, limit: limit, term });
        console.log(response, "res");
        await setUsers(response?.data?.data?.data);
        // console.log(response?.data);
        setCount(response.data?.data?.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const erc20 = new ethers.Contract(T4Address, TT4LToken, provider);

    try {
      const tokenName = await erc20.name();
      const tokenSymbol = await erc20.symbol();
      const totalSupply = await erc20.totalSupply();
      await setContractInfo({
        address: T4Address,
        tokenName,
        tokenSymbol,
        totalSupply,
      });
      await console.log(contractInfo);
      await setStage(stage + 1);
      await setLoading(false);
    } catch (err) {
      alert("No wallet found");
      console.log(err);
    }
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };
  const columns = [
    { label: "walletAddress", value: (player) => player.leaf.walletAddress || "" },
    { label: "userId", value: (player) => player.leaf.userId || "" },
    { label: "proof", value: (player) => player.leaf.proof || "" },
    // { label: "gender", value: (player) => player?.gender || "" },
    { label: "amount", value: (player) => player?.leaf.amount || "" },
  ];
  const downloadExcel = (d) => {
    console.log("d", d.data?.data);
    const headerRow = columns?.map((column) => ({ value: column.label, type: "string" }));
    const dataRows = d.data?.data?.map((player, search) =>
      columns?.map((column) => ({
        value: column.value(player),
        type: "string",
      }))
    );
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `420 Merkle tree`, sheet: { data } });
  };
  const totalData = async () => {
    const data = await getMerkle({ page: page + 1, limit: count, term });

    // setSearch(data);
    // console.log(data, "data");
    // setTotal(data);
    await downloadExcel(data.data);
  };
  const handleTransfer = async (id) => {
    const res = await deleteRoot(id);
    console.log(res.data);
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // await provider.send("eth_requestAccounts", []);
    // const signer = await provider.getSigner();
    // const erc20 = new ethers.Contract(contractInfo.address, TT4LToken, signer);
    // console.log("amount", amount)
    // // const proofArray =await proof.split(',').map((item) => ethers.utils.formatBytes32String(item.trim()));
    // const proofArray = proof.split(',').map((item) => ethers.utils.arrayify(item.trim()));
    // try {
    //   const transaction = await erc20.mintRewards(amount, proofArray);
    //   await transaction.wait(); // Wait for the transaction to be mined
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  console.log(users, "users");
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Container
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ color: "#FFF" }}>Loading...</div>
        </Container>
      ) : (
        <>
          <Grid container spacing={6}>
            {users.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : (
              <>
                <Grid item xs={12}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px -1px",
                      }}
                    >
                      <Box width={400} margin="20px" pr={1}>
                        {/* <MDInput
                          label="Search..."
                          fullWidth
                          value={term}
                          size="large"
                          onChange={handletitle}
                        /> */}
                        <TextField
                          label="Search..."
                          fullWidth
                          value={term}
                          size="large"
                          onChange={handletitle}
                        />
                      </Box>
                      {/* <div style={{ marginLeft: "20%", marginBottom: "10%" }}>
                        <Button  onClick={handleSubmit}>
                          {stage === 0 ? "Initiate Wallet " : "Connected "}
                          <Icon>
                            <img width={30} src="https://img.icons8.com/color/48/plus--v1.png" />
                          </Icon>
                        </Button>
                      </div> */}
                      <MDTypography component="label" style={{ padding: "80px" }}>
                        {/* Add label text here */}
                      </MDTypography>
                      {/* {username === "admin" && ( */}
                      <div style={{ margin: "20px" }}>
                        <MDButton variant="contained" onClick={totalData} color="primary">
                          Export to Excel
                        </MDButton>
                      </div>
                      {/* )} */}
                    </div>
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Wallet Address</StyledTableCell>
                            <StyledTableCell>Leaf</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>View</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {users?.map((user) => (
                            <StyledTableRow key={user._id}>
                              <StyledTableCell>{user.leaf.walletAddress}</StyledTableCell>
                              <StyledTableCell>{user.leaf.proof}</StyledTableCell>
                              <StyledTableCell>
                                {(Number(user.leaf.amount) / 1e18).toFixed(2)}
                                {/* {Number(user.leaf.amount).toFixed(2)} */}
                              </StyledTableCell>
                              <StyledTableCell>
                                <MDButton onClick={() => handleTransfer(user._id)} color="info">
                                  Delete
                                </MDButton>
                              </StyledTableCell>
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={count}
                    style={{ color: "white" }}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default Users;
