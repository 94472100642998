/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  Paper,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { styled } from "@mui/system";
import MDButton from "components/MDButton";
import { sendReward } from "services/api/gameAPI";
import { searchUser } from "services/api/gameAPI";

export const useStyles = styled((theme) => ({
  searchCard: {
    // Add your custom styling here
    marginTop: "20px",
  },
  suggestionItem: {
    // Add your custom styling here
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",

    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
}));

const walletAmount = 10000;
function AdminTransactionForm() {
  const [query, setQuery] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectUser, setSelectUser] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [walletAmount, setWalletAmount] = useState(10000);
  const [currency, setCurrency] = React.useState("");
  let navigate = useNavigate();
  React.useEffect(() => {
    const fetchWallet = async () => {
      try {
        const res = await fetch(`http://localhost:3001/adminWallet/api/wallet/post`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 401 || res.status === 403) {
          // alert(`${UN_AUTH_STATUS}`);
          //  history.getBack();
        } else {
          const data = await res.json();
          console.log(data);
          setCurrentAmount(
            data?.map((bill) => bill.tokenValue)?.reduce((acc, tokenValue) => acc + tokenValue)
          );
          console.log(currentAmount);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchWallet();
  }, [currentAmount]);

  const getValueInput = (event) => {
    setAmount(event.target.value);
  };

  const onSubmit = async () => {
    try {
      const adminBalance = (await parseInt(walletAmount)) - parseInt(currentAmount);

      const response = await sendReward({
        userId: selectUser,
        adminId: "6493d691e971f32a489f47d8",
        tokenCount: parseInt(amount),
        adminBalance: 10000,
        paymentMethod: currency,
      });

      // if (response.status === 201) {
      //   console.log(response);
      //   await setWalletAmount(walletAmount - currentAmount);
      //   alert(`${currency} submitted successfully`);
      // } else {
      //   // alert(`${UN_AUTH_STATUS}`);
      // }
      // alert("successfully sent");
      // window.location.reload(false);

      if (response) {
        alert("Success! The reward has been successfully sent to the user.");
        window.location.reload(false);
      }
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        // alert(`${UN_AUTH_STATUS}`);
        // history.getBack();
        alert(
          "Oops! It seems there was an issue while trying to send the admin reward to the user. Please try again"
        );
      } else {
        console.log("err", error);
        alert(
          "Oops! It seems there was an issue while trying to send the admin reward to the user. Please try again"
        );
      }
    }
  };

  async function handleSuggestionClick(suggestion) {
    console.log(suggestion);
    setQuery(suggestion?.username);
    await setSelectUser(suggestion?._id);
    await console.log(suggestion?._id);
    setSuggestions([]);
  }
  const classes = useStyles();
  async function handleInputChange(event) {
    const newQuery = event.target.value;
    setQuery(newQuery);
    const newSuggestions = await fetchSuggestions(newQuery);
    console.log(newSuggestions);
    setSuggestions(newSuggestions);
  }
  async function fetchSuggestions(query) {
    const response = await searchUser(query);
    //const data = await response.json();
    return response.data;
  }

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  // const submit = () => {

  //   navigate('distribution/table');
  // };

  const tokenType = [
    {
      value: "USDC",
      label: "USDC",
    },
    {
      value: "CHAMPS",
      label: "CHAMPS",
    },
  ];

  console.log(currency, "currency");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm">
        <Link to={"/reward/table"}>
          {" "}
          <MDButton variant="gradient" color="dark">
            Wallet Transactions
          </MDButton>
        </Link>

        <Card elevation={3} sx={{ mt: 4, p: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Admin Wallet Balance: {!currency ? "" : walletAmount - currentAmount} {currency}
          </Typography>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField label="From" disabled value="Admin" />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Search"
                value={query}
                size="small"
                placeholder="Input user"
                onChange={handleInputChange}
              />
            </FormControl>

            {query === "" ? (
              ""
            ) : (
              <div id="search-card" className={classes.searchCard}>
                <Paper sx={{ width: "200px" }}>
                  <List id="suggestion-list" style={{ fontSize: "14px" }}>
                    {suggestions.map((suggestion) => (
                      <ListItem
                        key={suggestion._id}
                        button
                        className={classes.suggestionItem}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion?.username}
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </div>
            )}
            <FormControl fullWidth>
              <TextField
                type="number"
                label="Amount*"
                onChange={getValueInput}
                placeholder="Input token"
              />
              {amount < 0 && (
                <Typography variant="body2" color="error">
                  Token should be greater than zero
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Token</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                label="Token"
                sx={{
                  height: 45,
                }}
                onChange={handleChange}
              >
                {tokenType.map((type, i) => (
                  <MenuItem key={i} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                disabled={selectUser === null || amount === null || amount < 1 || !currency}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
    </DashboardLayout>
  );
}

export default AdminTransactionForm;
